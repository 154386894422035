import React from "react";

function CirclesIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="66"
            height="67"
            fill="none"
            viewBox="0 0 66 67"
        >
            <circle
                cx="31.5"
                cy="31.5"
                r="31.5"
                fill="#2F9FB2"
                fillOpacity="0.1"
            ></circle>
            <circle
                cx="52.5"
                cy="53.5"
                r="13.5"
                fill="#2F9FB2"
                fillOpacity="0.1"
            ></circle>
        </svg>
    );
}

export default CirclesIcon;
