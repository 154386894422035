import React, { ReactElement, useState } from "react";
import styles from "./styles.module.scss";
import { ChangeEvent } from "react";
import S3Icon from "@components/shared/s3Icon";

import { useDomainContext } from "@hooks/useDomainContext";
import CirclesIcon from "../icons/circles";
export default function MainBanner(): ReactElement {
    const { domain, formattedTitle } = useDomainContext();

    const categories = domain?.categories ?? [];

    const [filteredCategories, setFilteredCategories] = useState(categories);
    const changeInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setFilteredCategories(
            categories.filter((el) => {
                return el.name
                    .toLowerCase()
                    .includes(e.target.value.trim().toLowerCase());
            }),
        );
    };

    return (
        <section className={`${styles["mainSection"]}`}>
            <div className="pt-24 pb-44">
                <div className="text-center " style={{ color: "#fff" }}>
                    <h1
                        className="font-bold text-xl lg:text-3xl"
                        dangerouslySetInnerHTML={{
                            __html:
                                formattedTitle ??
                                "Your Ultimate Business Solution Center",
                        }}
                    ></h1>
                    <p className="font-light mt-3 max-w-3xl mx-auto">
                        Choose your service, compare deals, and receive free
                        bids from our network of qualified professionals!
                    </p>
                </div>
                <input
                    type="text"
                    className="block mx-auto mt-9 sm:w-[570px] w-[95%] h-[50px] rounded-5 px-5"
                    placeholder="Search services like crm, card processing, etc..."
                    onChange={changeInputHandler}
                />
                {filteredCategories.length ? (
                    <div className={` ${styles["categories-wrapper"]}`}>
                        {filteredCategories?.map((category, index) => {
                            return (
                                <div
                                    className={styles["category-box"]}
                                    key={index}
                                >
                                    <div className="relative">
                                        {category.icon && (
                                            <S3Icon
                                                image={category.icon}
                                                svgClassName={
                                                    styles["category-icon"]
                                                }
                                            />
                                        )}
                                        <span className="block absolute bottom-[5px] left-3">
                                            <CirclesIcon />
                                        </span>
                                    </div>

                                    <span>
                                        {category.label
                                            ? category.label
                                            : category.name}
                                    </span>
                                    <a
                                        href={`/${
                                            category.slugAlias ?? category.slug
                                        }`}
                                    >
                                        Get a Free Quote
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="text-center mt-20 capitalize font-semibold text-white">
                        sorry no results found
                    </div>
                )}
            </div>
        </section>
    );
}
